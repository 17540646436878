import {Observable} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {Store} from '@ngxs/store';
import {Navigate} from '@ngxs/router-plugin';
import {AuthState} from '../../states/auth/auth.state';
import {AuthService} from '../../services/auth/auth.service';
import {Settings} from '../../../../conf/settings.base';

@Injectable({
  providedIn: 'root',
})
export class AuthenticatedGuard {
  constructor(private store: Store, private auth: AuthService, private settings: Settings) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const isAuthenticated = this.store.selectSnapshot(AuthState.isAuthenticated);
    if (isAuthenticated) {
      return true;
    }

    return this.auth.getCurrentUser().pipe(
      tap(user => {
        const redirect = state.url === '/' || state.url === '' ? null : state.url;

        if (!user.is_authenticated) {
          this.store.dispatch(new Navigate([this.settings.AUTHENTICATION_REQUIRED_REDIRECT_URL], {redirect}));
        } else if (user.is_2fa_enabled && !user.is_authenticated_2fa) {
          this.store.dispatch(new Navigate(['/auth/sign-in-2fa'], {redirect}));
        }
      }),
      map(
        user =>
          (user.is_authenticated && !user.is_2fa_enabled) || (user.is_2fa_enabled && user.is_authenticated && user.is_authenticated_2fa)
      )
    );
  }
}
